// extracted by mini-css-extract-plugin
export var root = "OrderItem-module--root--npESe";
export var paddingBottom = "OrderItem-module--paddingBottom--pebnj";
export var orderId = "OrderItem-module--orderId--pYnEE";
export var orderTotalMeta = "OrderItem-module--orderTotalMeta--FIP6r";
export var total = "OrderItem-module--total--P-IKd";
export var orderDate = "OrderItem-module--orderDate--EX9AH";
export var lastUpdate = "OrderItem-module--lastUpdate--kdEjW";
export var status = "OrderItem-module--status--0TSrw";
export var detailsContainer = "OrderItem-module--detailsContainer--L9t-T";
export var show = "OrderItem-module--show--PDVc9";
export var hide = "OrderItem-module--hide--AkHsR";
export var orderHeader = "OrderItem-module--orderHeader--VpUof";
export var addressDetailContainer = "OrderItem-module--addressDetailContainer--fF4M8";
export var addressContainer = "OrderItem-module--addressContainer--TK5qs";
export var addressMeta = "OrderItem-module--addressMeta--tIWeD";
export var address = "OrderItem-module--address--bE2by";
export var itemList = "OrderItem-module--itemList--fYLZZ";
export var itemContainer = "OrderItem-module--itemContainer--UWgEl";
export var imageContainer = "OrderItem-module--imageContainer--zfhoF";
export var itemName = "OrderItem-module--itemName--hxexq";
export var itemQuantity = "OrderItem-module--itemQuantity--vpgyl";
export var itemTotal = "OrderItem-module--itemTotal--Rieh3";
export var transactionDetails = "OrderItem-module--transactionDetails--WTRnO";
export var transactionalGrid = "OrderItem-module--transactionalGrid--fF30b";
export var bold = "OrderItem-module--bold--sBL3o";
export var grandTotal = "OrderItem-module--grandTotal--7AS+I";
export var toggleContainer = "OrderItem-module--toggleContainer--1dg7z";
export var mobileLabel = "OrderItem-module--mobileLabel--hpwYj";
export var rotate = "OrderItem-module--rotate--dkwJz";
export var itemTotalMobile = "OrderItem-module--itemTotalMobile--UDF4V";
export var orderMeta = "OrderItem-module--orderMeta--1oQMW";
export var od = "OrderItem-module--od--+DjSn";
export var st = "OrderItem-module--st--lFPVn";
export var orderItemMeta = "OrderItem-module--orderItemMeta--iBUI6";